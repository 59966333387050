<template>
  <div>
    <div class="flex alcenter center">
      <vue-qr :text="textParam" :size="sizeParam"></vue-qr>
    </div>
    <div class="mt16">
      <div class="ft12 ftwblod cl-info text-center">使用服务人员端扫一扫</div>
      <div class="ft12 ftwblod cl-info text-center">保存请使用”右键-另存为“</div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
  name: "vueQrCode",
  //对外暴露的传入参数 text：生成的文本 size：边框大小
  props:{text:{type:String,default:()=>""},size:{type:Number,default:()=>200}},
  components:{
    VueQr,
  },
  data(){
    return {

    }
  },
  created() {
  },
  methods:{
  },
  computed:{
    textParam:function (){
      return this.text;
    },
    sizeParam:function (){
      return this.size;
    }
  }
}
</script>

<style>
</style>
